/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
export var FETCH_SANDBOX = 'FETCH_SANDBOX';
export var FETCH_SANDBOX_SUCCESS = 'FETCH_SANDBOX_SUCCESS';
export var FETCH_SANDBOX_FAILED = 'FETCH_SANDBOX_FAILED';
export var CREATE_SANDBOX = 'CREATE_SANDBOX';
export var CREATE_SANDBOX_SUCCESS = 'CREATE_SANDBOX_SUCCESS';
export var CREATE_SANDBOX_FAILED = 'CREATE_SANDBOX_FAILED';
export var POLL_SANDBOX = 'POLL_SANDBOX';
export var POLL_SANDBOX_SUCCESS = 'POLL_SANDBOX_SUCCESS';
export var POLL_SANDBOX_FAILED = 'POLL_SANDBOX_FAILED';
export var GET_USE_CASES = 'GET_USE_CASES';
export var GET_USE_CASES_SUCCESS = 'GET_USE_CASES_SUCCESS';
export var GET_USE_CASES_FAILED = 'GET_USE_CASES_FAILED';
