export default (function (state, action) {
    if (state === void 0) { state = { data: {} }; }
    switch (action.type) {
        // ===== GET USE CASES =====
        case 'SHOW_MODAL':
            return {
                type: action.data.type,
                data: action.data.data || {},
            };
        case 'HIDE_MODAL':
            return {
                type: null,
                isIncomplete: action.isIncomplete,
                data: {},
            };
        default:
            return state;
    }
});
