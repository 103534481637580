/**
 * app.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
// Needed for redux-saga es6 generator support
import '@babel/polyfill';
// Import all the third party stuff
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { Route, BrowserRouter } from 'react-router-dom';
import 'sanitize.css/sanitize.css';
import '!file-loader?name=[name].[ext]!./assets/images/favicon.ico';
import 'assets/styles/theme.scss';
import Container from 'containers/App/AppContainer';
import store from './configureStore';
import appSetup from './appSetup';
import 'fomantic-ui-less/semantic.less';
import { Auth0Provider } from '@auth0/auth0-react';
import constants from './constants/global-constants';
appSetup.run();
var MOUNT_NODE = document.getElementById('app');
var render = function () {
    ReactDOM.render(React.createElement(Auth0Provider, { domain: constants.getAuth0Config().domain, clientId: constants.getAuth0Config().clientID, redirectUri: window.location.origin },
        React.createElement(Provider, { store: store },
            React.createElement(BrowserRouter, null,
                React.createElement(ErrorBoundary, null,
                    React.createElement(Route, { path: "/", component: Container }))))), MOUNT_NODE);
};
if (module.hot) {
    // Hot reloadable React components and translation json files
    // modules.hot.accept does not accept dynamic dependencies,
    // have to be constants at compile-time
    module.hot.accept(['containers/App/App'], function () {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        render();
    });
}
render();
