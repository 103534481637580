import { AuthConfig } from './authConstants';
var getEnv = function () {
    var hostname = window.location.hostname;
    switch (hostname) {
        case 'sandbox.neo4j.com':
        case 'sandbox-v3.netlify.app':
        case 'master--sandbox-v3.netlify.app':
            return 'prod';
        case 'localhost':
            return 'dev';
        case 'sandbox-dev.neo4j.com':
            return 'dev';
        case 'sandbox-staging.neo4j.com':
            return 'staging';
        default:
            return 'dev';
    }
};
var constants = {
    IS_MAINTENANCE_ENABLED: false,
    ENV: getEnv(),
    APP_VERSION: '3.0',
    API_BASE_URL_v2: ['prod', 'staging'].indexOf(getEnv()) > -1
        ? 'https://api.sandbox.neo4j.com/main'
        : 'https://api.sandbox-dev.neo4j.com/development',
    SANDBOX_DOMAIN: ['prod', 'staging'].indexOf(getEnv()) > -1 ? 'neo4jsandbox.com' : 'development.neo4jsandbox.com',
    SANDBOX_BOLT_DOMAIN: ['prod', 'staging'].indexOf(getEnv()) > -1 ? 'bolt.neo4jsandbox.com' : 'bolt.development.neo4jsandbox.com',
    NODE_API_BASE_URL: getEnv() !== 'dev'
        ? 'https://um9mgpkdbj.execute-api.us-east-1.amazonaws.com/prod'
        : 'https://a1wuuize86.execute-api.us-east-1.amazonaws.com/dev',
    INTERNAL_API_BASE_URL: ['prod', 'staging'].indexOf(getEnv()) > -1
        ? 'https://internal-api.neo4jsandbox.com/v1'
        : 'https://internal-api.development.neo4jsandbox.com/v1',
    MIXPANEL_ID: getEnv() === 'prod' ? '4bfb2414ab973c741b6f067bf06d5575' : 'ef1696f0a9c88563894dcba2019a9bef',
    GOOGLE_CLIENT_ID: getEnv() === 'dev'
        ? '831206160256-vcpqe5ff31kk7ss7bdi3i7581hsubvdm.apps.googleusercontent.com'
        : '988750056402-mrf6ldf3kqekcmosov47608jappj4ob9.apps.googleusercontent.com',
    GOOGLE_DEVELOPER_KEY: getEnv() === 'dev' ? 'AIzaSyDC35Om-i9gxztPGwcO4JDm6G07__KVxyw' : 'AIzaSyC9-zUfjKHQ_YStpo_W8O2DRLJsB3Z1wwE',
    GOOGLE_APP_ID: '831206160256',
    SANDBOX_CREATE_ENV: 'v2',
    getAuth0Config: function () { return (['prod', 'staging'].indexOf(getEnv()) > -1 ? constants.AUTH0.config : constants.AUTH0DEV.config); },
    AUTH0DEV: {
        jsConfig: {
            domain: 'neo4j-dev.eu.auth0.com',
            clientID: 'Mza0IjV81ETtZLkZFOOAviFYzF813oj6',
            audience: 'neo4j://accountinfo/',
            scope: 'read:account-info openid email profile user_metadata',
            responseType: 'token id_token',
            redirectUri: window.location.origin,
        },
        config: {
            clientID: 'Mza0IjV81ETtZLkZFOOAviFYzF813oj6',
            domain: 'neo4j-dev.eu.auth0.com',
            config: AuthConfig,
        },
        logoutUrl: window.location.origin,
    },
    AUTH0: {
        jsConfig: {
            domain: 'neo4j-dev.eu.auth0.com',
            clientID: 'Mza0IjV81ETtZLkZFOOAviFYzF813oj6',
            audience: 'neo4j://accountinfo/',
            scope: 'read:account-info openid email profile user_metadata',
            responseType: 'token id_token',
            redirectUri: window.location.origin,
        },
        config: {
            clientID: 'DxhmiF8TCeznI7Xoi08UyYScLGZnk4ke',
            domain: 'login.neo4j.com',
            config: AuthConfig,
        },
        logoutUrl: window.location.origin,
    },
    ACTION_BUTTON: {
        launch: 'Open with Browser',
        launchBloom: 'Open with Bloom',
    },
    userDataKey: {
        cookies: {
            accessToken: 'com.neo4j.accounts.sandbox.accessToken',
            idToken: 'com.neo4j.accounts.sandbox.idToken',
            userInfo: 'com.neo4j.accounts.sandbox.userInfo',
            // userInfov2: 'com.neo4j.accounts.userInfo',
        },
        localStorage: {
            userProfile: 'com.neo4j.accounts.sandbox.userProfile',
        },
    },
    testing: {
        expires: null,
        // These variables will always be false in prod env
        extend: getEnv() === 'dev' && false,
        terminate: getEnv() === 'dev' && false,
        create: getEnv() === 'dev' && false,
        sampleSandboxPending: {
            status: 'PENDING',
            modelImage: '//neo4jsandbox.com/img/blank.png',
            expires: 1572416036030,
            usecase: 'network-management',
            running: true,
            taskId: 'arn:aws:ecs:us-east-1:715633473519:task/99613b4e-8b5c-4bf8-a01d-c63a01b5a6bc',
            password: 'hinge-overcoat-slice',
            sandboxHashKey: '5f450c721af36cafe3a03d8ebd99',
        },
        sampleSandboxCreated: {
            name: 'Shreyans Gandhi',
            modelImage: '//neo4jsandbox.com/img/blank.png',
            ip: '100.26.253.13',
            expires: 1572416036030,
            sbRel: 'IS_ALLOCATED',
            sandboxHashKey: '5f450c721af36cafe3a03d8ebd99',
            sandboxId: 891868,
            usecase: 'network-management',
            sbType: 'Sandbox',
            taskid: 'arn:aws:ecs:us-east-1:715633473519:task/99613b4e-8b5c-4bf8-a01d-c63a01b5a6bc',
            backupPort: 35073,
            connectionVerified: true,
            password: 'hinge-overcoat-slice',
            boltPort: 35074,
            port: 35075,
            privip: '10.0.1.192',
            jupyterPort: false,
        },
    },
    additionalUc: [
        'yelp',
        'twitter',
        'fundamentals',
        'yelp',
        'citations',
        'fraud-detection',
        'contact-tracing',
        'openstreetmap',
        'fincen',
        'movies',
        'graph-data-science-blank-sandbox',
    ],
    bloomUseCases: ['bloom', 'graph-data-science2', 'fraud-detection', 'contact-tracing', 'blank-sandbox', 'openstreetmap', 'fincen'],
    beginnerUsecases: ['movies', 'graph-data-science2'],
    intermediateUsecases: ['openstreetmap'],
    graphqlUsecases: ['recommendations', 'icij-panama-papers', 'openstreetmap', 'twitter-v2'],
    gdsFocusedUsecases: ['graph-data-science2', 'bloom'],
    gdsUsecases: ['graph-data-science2', 'bloom', 'twitch', 'fraud-detection', 'pole', 'recommendations', 'contact-tracing', 'twitter-trolls'],
    dictionary: {
        launchProject: 'Create',
        launchAndDownloadProject: 'Create and Download credentials'
    },
    keys: {
        FIRST_NAME: 'first_name',
        LAST_NAME: 'last_name',
        COMPANY: 'company',
    }
};
export default constants;
