import * as Sentry from '@sentry/browser';
import Axios from 'axios';
import Tracking from 'app/tracking/index';
import * as queryString from 'query-string';
import logger from 'app/helpers/logger';
import globalConstant from './constants/global-constants';
var mixpanel = require('mixpanel-browser');
var trackingEndpoints = [
    'SandboxStopInstance',
    'SandboxRunInstance',
    'SandboxGetUsecases',
    'SandboxExtend',
    'SandboxBackupInstance',
];
export default {
    run: function () {
        // Sentry init
        if (globalConstant.ENV !== 'dev') {
            Sentry.init({
                dsn: 'https://a29280a286db4fb9bb159f6c1a815168@sentry.io/1809110',
                environment: globalConstant.ENV,
            });
        }
        // Global Variable
        // initializing Neo4j Sandbox global vairbale
        window.neo4jSandbox = {
            analytics: {},
            tracking: new Tracking(),
        };
        // Handle Params in URL
        var hash = queryString.parse(window.location.search);
        if (window.localStorage) {
            if (hash.usecase &&
                globalConstant.additionalUc.indexOf(hash.usecase) > -1) {
                var currentAddtionalUc = window.localStorage.getItem('additionalUc');
                var newAdditionalUc = currentAddtionalUc
                    ? JSON.parse(currentAddtionalUc)
                    : [];
                newAdditionalUc = newAdditionalUc
                    .filter(function (usecase) { return usecase !== hash.usecase; })
                    .concat([hash.usecase]);
                window.localStorage.setItem('additionalUc', JSON.stringify(newAdditionalUc));
            }
            // If the `persona` param exist in the URL, then store it in localstorage so that we can customize 
            // the UI based on it (specifically for Data Science audience)
            if (hash.persona)
                localStorage.setItem('neo4j_persona', hash.persona);
            // Handle UTM_Params
            if (hash.program_name) {
                localStorage.setItem('neo_program_name', hash.program_name);
            }
        }
        // Axios Global Catch
        Axios.interceptors.response.use(function (response) { return response; }, function (error) {
            var shouldTrackInSentry = globalConstant.ENV !== 'dev' &&
                trackingEndpoints.some(function (element) { return error.config.url.indexOf(element) > -1; });
            if (shouldTrackInSentry) {
                Sentry.captureException(error);
            }
            else {
                logger.log('Sentry - Axios Errored: Not tracked in dev env');
            }
            return Promise.reject(error);
        });
    },
};
