var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export default (function (state, action) {
    var _a;
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case 'USER_LOGGING_IN':
            return __assign(__assign({}, state), { isLoggingIn: true });
        case 'USER_LOGIN_SUCCESS':
            return __assign(__assign(__assign({}, state), action.user), { isLoggingIn: false });
        case 'UPDATE_USER_SUCCESS':
            return __assign(__assign(__assign({}, state), action.user), (action.meta || {}));
        case 'UPDATE_USER_KEY':
            if (!action.key || !action.value)
                return state;
            return __assign(__assign({}, state), (_a = {}, _a[action.key] = action.value, _a));
        case 'USER_META_UPDATE_SUCCESS':
            return __assign(__assign({}, state), action.user);
        case 'USER_LOGOUT':
            return {
            // ...state,
            // profile: null,
            // accessToken: null,
            };
        default:
            return state;
    }
});
