var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Axios from 'axios';
import to from 'await-to-js';
import store from 'app/configureStore';
import globalCostants from '../constants/global-constants';
import logger from '../helpers/logger';
var ApiClient = /** @class */ (function () {
    function ApiClient() {
        var storeData = store.getState();
        this.accessToken = storeData.user.accessToken;
        this.buildHeaders();
    }
    ApiClient.prototype.get = function (url, params) {
        if (params === void 0) { params = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var _a, err, response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, to(Axios.get("".concat(globalCostants.API_BASE_URL_v2).concat(url), __assign({ headers: this.headers }, params)))];
                    case 1:
                        _a = _b.sent(), err = _a[0], response = _a[1];
                        ApiClient.conditionalHandleError(err);
                        return [2 /*return*/, [err, response]];
                }
            });
        });
    };
    ApiClient.prototype.post = function (url, data, params) {
        if (data === void 0) { data = {}; }
        if (params === void 0) { params = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var _a, err, response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, to(Axios.post("".concat(globalCostants.API_BASE_URL_v2).concat('/SandboxRunInstance'), data, __assign({ headers: {
                                Authorization: this.accessToken,
                                Accept: 'application/json, text/javascript, */*; q=0.01',
                                'Content-Type': 'application/json',
                            } }, params)))];
                    case 1:
                        _a = _b.sent(), err = _a[0], response = _a[1];
                        // const [err, response] = await to(Axios.post(
                        // 	`${globalCostants.API_BASE_URL_v2}/SandboxRunInstance`,
                        // 	{
                        // 		usecase: data.usecase,
                        // 		draft_only: false,
                        // 	},
                        // 	{
                        // 		headers: {
                        // 			Authorization: this.accessToken,
                        // 			Accept: 'application/json, text/javascript, */*; q=0.01',
                        // 			'Content-Type': 'application/json',
                        // 		},
                        // 	},
                        // ))
                        // ApiClient.conditionalHandleError(err);
                        return [2 /*return*/, [err, response]];
                }
            });
        });
    };
    ApiClient.conditionalHandleError = function (err) {
        if (err) {
            logger.error(err);
        }
    };
    ApiClient.prototype.buildHeaders = function () {
        this.headers = {
            Accept: 'application/json, text/javascript, */*; q=0.01',
            'Content-Type': 'application/json',
        };
        if (this.accessToken && this.accessToken !== '')
            this.headers.Authorization = "".concat(this.accessToken);
        this.postHeaders = {
            'Cache-Control': 'max-age=0',
            'Content-Type': 'application/json',
            Authorization: this.accessToken
        };
    };
    return ApiClient;
}());
export default ApiClient;
