import { combineReducers } from 'redux';
import sandboxReducer from './sandboxReducer';
import userReducer from './userReducer';
import modalReducer from './modalReducer';
import appReducer from './appReducer';
export default combineReducers({
    sandbox: sandboxReducer,
    user: userReducer,
    activeModal: modalReducer,
    app: appReducer,
});
