var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import AsyncComponent from 'app/components/AsyncComponent';
var MobileIndex = AsyncComponent(function () { return import('containers/Mobile/MobileIndex'); });
var App = AsyncComponent(function () { return import('./App'); });
var MobileDetect = require('mobile-detect');
var md = new MobileDetect(window.navigator.userAgent);
var AppContainer = function (props) {
    var location = props.location, history = props.history;
    if (md.mobile() && !md.tablet() && location.pathname !== '/mobile') {
        history.push(__assign(__assign({}, location), { pathname: '/mobile' }));
        return null;
    }
    React.useEffect(function () {
        if (window.neo4jSandbox)
            window.neo4jSandbox.tracking.routeChangeTracking(location);
    }, [location]);
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/mobile", component: MobileIndex }),
        React.createElement(Route, { exact: true, path: "*", component: App })));
};
export default AppContainer;
