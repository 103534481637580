var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var trackingMiddleware = function (store) { return function (next) { return function (action) {
    var tracking = window.neo4jSandbox.tracking;
    if (!tracking)
        return next(action);
    var currentState = store.getState();
    switch (action.type) {
        case 'POLL_SANDBOX_SUCCESS':
            tracking.event('SANDBOX_CREATED', {});
            break;
        case 'CREATE_SANDBOX_SUCCESS':
            tracking.event('SANDBOX_CREATE', {
                usecase: action.sandbox.usecase,
            });
            break;
        case 'EXTEND_SANDBOX_SUCCESS':
            {
                var sandboxExtendAnalyticsMeta = window.neo4jSandbox.analytics &&
                    window.neo4jSandbox.analytics.lastExtendPopup;
                tracking.event('SANDBOX_EXTEND', __assign(__assign({}, sandboxExtendAnalyticsMeta), { usecase: action.sandbox.usecase }));
            }
            break;
        case 'TERMINATE_SANDBOX_SUCCESS':
            {
                var sandboxterminateAnalyticsMeta = window.neo4jSandbox.analytics &&
                    window.neo4jSandbox.analytics.lastTerminatePopup;
                tracking.event('SANDBOX_TERMINATE', __assign(__assign({}, sandboxterminateAnalyticsMeta), { usecase: action.sandbox.usecase }));
            }
            break;
        case 'INVITE_TO_SANDBOX_SUCCESS':
            {
                var activeModal = currentState.activeModal;
                var usecase = activeModal.data.usecase;
                var sandboxInviteAnalyticsMeta = window.neo4jSandbox.analytics &&
                    window.neo4jSandbox.analytics.lastInvitePopup;
                tracking.event('SANDBOX_INVITE', __assign(__assign({}, sandboxInviteAnalyticsMeta), { usecase: usecase }));
            }
            break;
        case 'ACCEPT_INVITATION_SUCCESS':
            tracking.event('SANDBOX_ACCEPT_INVITE', {
                usecase: action.data.usecase,
                token: action.data.token,
            });
            break;
        case 'HIDE_MODAL':
            {
                if (action.isIncomplete !== true)
                    break;
                var activeModal = currentState.activeModal;
                var modalType = activeModal.type;
                if (modalType === 'create') {
                    tracking.event('SANDBOX_CREATE_INCOMPLETE', {
                        closedAt: Date.now(),
                    });
                    break;
                }
                var meta = { closedAt: Date.now() };
                if (modalType === 'extend') {
                    meta = __assign(__assign({}, meta), window.neo4jSandbox.analytics.lastExtendPopup);
                    tracking.event('SANDBOX_EXTEND_INCOMPLETE', meta);
                    break;
                }
                if (modalType === 'terminate') {
                    meta = __assign(__assign({}, meta), window.neo4jSandbox.analytics.lastTerminatePopup);
                    tracking.event('SANDBOX_TERMINATE_INCOMPLETE', meta);
                    break;
                }
                if (modalType === 'invite') {
                    meta = __assign(__assign({}, meta), window.neo4jSandbox.analytics.lastInvitePopup);
                    tracking.event('SANDBOX_INVITE_INCOMPLETE', meta);
                    break;
                }
                if (modalType === 'pushAura') {
                    meta = __assign(__assign({}, meta), window.neo4jSandbox.analytics.lastInvitePopup);
                    tracking.event('SANDBOX_PUSH_AURA_INCOMPLETE', meta);
                    break;
                }
            }
            break;
        case 'USER_META_UPDATE_FAILED':
            tracking.event('SANDBOX_USER_META_UPDATE_FAILED');
            break;
        case 'USER_META_UPDATE_SUCCESS':
            tracking.event('USER_META_UPDATE_SUCCESS');
            break;
        default:
            break;
    }
    return next(action);
}; }; };
export default trackingMiddleware;
