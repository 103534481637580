/* eslint-disable no-console */
var environment = process.env.NODE_ENV;
var logsEnabled = environment === 'development';
var log = function () {
    var data = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        data[_i] = arguments[_i];
    }
    if (logsEnabled)
        console.log.apply(console, data);
};
var info = function () {
    var data = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        data[_i] = arguments[_i];
    }
    if (logsEnabled)
        console.info.apply(console, data);
};
var debug = function () {
    var data = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        data[_i] = arguments[_i];
    }
    if (logsEnabled)
        console.debug.apply(console, data);
};
var warn = function () {
    var data = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        data[_i] = arguments[_i];
    }
    if (logsEnabled)
        console.warn.apply(console, data);
};
var error = function () {
    var data = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        data[_i] = arguments[_i];
    }
    if (logsEnabled)
        console.error.apply(console, data);
};
export default { log: log, info: info, debug: debug, warn: warn, error: error };
