import Neo4jLogo from 'app/assets/images/neo4j_logo.png';
export var AuthConfig = {
    allowedConnections: ['google-oauth2', 'linkedin', 'twitter', 'Username-Password-Authentication'],
    additionalSignUpFields: [
        {
            name: 'first_name',
            placeholder: 'First Name',
        },
        {
            name: 'last_name',
            placeholder: 'Last Name',
        },
    ],
    closable: false,
    languageDictionary: {
        signUpTerms: "I agree to the <a href='https://neo4j.com/terms/online-trial-agreement/' style='text-decoration: underline' target='_blank'>terms of service</a> of Neo4j.",
        title: 'Sandbox',
    },
    mustAcceptTerms: true,
    display: {},
    auth: {
        redirect: true,
        redirectUrl: window.location.origin,
        responseType: 'token id_token',
        audience: 'neo4j://accountinfo/',
        params: {
            scope: 'read:account-info write:account-info openid email profile sandbox_appmeta',
        },
    },
    theme: {
        logo: Neo4jLogo,
        primaryColor: '#018BFF',
    }
};
