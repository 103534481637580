/*
 * src/store.js
 * With initialState
 */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';
import trackingMiddleware from 'app/tracking/trackingMiddleware';
import rootReducer from './reducers/rootReducer';
var composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
var store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(trackingMiddleware, createSentryMiddleware(Sentry), thunk)));
export default store;
