var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import globalConstants from '../constants/global-constants';
export default {
    dataFilter: function (action) {
        switch (action.type) {
            case 'Sandbox/FETCH_SANDBOX_SUCCESS':
                if (globalConstants.testing.expires) {
                    var items = action.sandbox.map(function (s) { return (__assign(__assign({}, s), { expires: globalConstants.testing.expires })); });
                    return __assign(__assign({}, action), { sandbox: items });
                }
                break;
            default:
                break;
        }
        return action;
    },
};
