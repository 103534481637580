import globalConstants from 'app/constants/global-constants';
import * as Sentry from '@sentry/browser';
import { getCookie, setCookie, getUserFromStore } from 'app/helpers/userHelper';
import logger from 'app/helpers/logger';
var mixpanel = require('mixpanel-browser');
var registerEventHook = function () {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'virtualPageview',
            vpv: '/virtual/sandbox-thanks',
        });
    }
};
var sandboxLaunchEventHook = function () {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'virtualPageview',
            vpv: '/virtual/browser-open/sandbox',
        });
    }
};
var sandboxCreateEventHook = function (meta) {
    mixpanel.people.append('Sandbox Created', meta.usecase || meta.usecase);
};
var sandboxExtendEventHook = function (meta) {
    mixpanel.people.append('Sandbox Extended', meta.usecase || meta.usecase);
};
var eventsHookMapper = {
    register: registerEventHook,
    SANDBOX_LAUNCH: sandboxLaunchEventHook,
    SANDBOX_CREATE: sandboxCreateEventHook,
    SANDBOX_EXTEND: sandboxExtendEventHook,
};
var Tracking = /** @class */ (function () {
    function Tracking() {
        var env = globalConstants.ENV;
        this.isProduction = env === 'prod';
        mixpanel.init(globalConstants.MIXPANEL_ID);
    }
    Tracking.prototype.trackLinkClick = function (e) {
        this.event('SANDBOX_LINK_CLICK', {
            text: e.target.text,
            href: e.target.href,
        });
    };
    Tracking.prototype.getIdentity = function (userId) {
        var domain = globalConstants.ENV !== 'dev' ? '.neo4j.com' : 'localhost';
        var identity = getCookie('neo_identity');
        var newIndetity = userId;
        // If there is a mismatch in current and new identity (meaning signed in with new email), update the identity
        if (!identity || (newIndetity && identity !== newIndetity)) {
            setCookie('neo_identity', newIndetity, { expires: 365, domain: domain });
            return newIndetity;
        }
        return identity || newIndetity;
    };
    Tracking.prototype.setUserIdentity = function (user) {
        var email = user.email;
        var firstName = user.first_name || user.given_name || (user.user_metadata && user.user_metadata.first_name) || '';
        var lastName = user.last_name || user.family_name || (user.user_metadata && user.user_metadata.last_name) || '';
        var name = user.name || "".concat(firstName, " ").concat(lastName);
        var identity = this.getIdentity(user.sub || user.userId);
        if (!identity) {
            Sentry.captureMessage('No identity');
        }
        if (identity) {
            // Mixpanel Identity
            mixpanel.identify(identity);
            mixpanel.people.set({
                $email: email,
                $name: name,
                $first_name: firstName,
                $last_name: lastName,
            });
            if (window.ga) {
                window.ga('set', 'userId', identity);
            }
            if (this.isProduction && user.email.indexOf('@neo') === -1) {
                // This is an example script - don't forget to change it!
            }
        }
        // Sentry Identity
        Sentry.configureScope(function (scope) {
            scope.setUser({ email: email });
        });
        if (window.Appcues) {
            window.Appcues.identify(identity, {
                name: name,
                firstName: firstName,
            });
        }
        // Setting Identity for GTM
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'sandbox_identify',
                neo_identity: identity,
                currentUser: {
                    displayName: name,
                    email: email,
                },
            });
        }
    };
    Tracking.prototype.setUserProperty = function (key, value) {
        var _a;
        mixpanel.people.set((_a = {}, _a[key] = value, _a));
        mixpanel.identify(mixpanel.get_property('mixpanel', 'distinct_id'));
    };
    Tracking.prototype.event = function (eventName, meta, platform) {
        if (platform === void 0) { platform = null; }
        var env = globalConstants.ENV;
        var currentUser = getUserFromStore();
        if (env !== 'prod')
            logger.log("TRACKING: ".concat(env, ": ").concat(eventName), 'tracking');
        if (env === 'prod' && currentUser && currentUser.email && currentUser.email.indexOf('@neo') > -1) {
            logger.log('not tracking in mixpanel. User has @neo in the email', 'tracking');
        }
        else {
            this.mixPanelTrack(eventName, meta);
        }
        if (env === 'prod') {
            if (window.ga) {
                window.ga('send', 'event', 'Sandbox-v3', eventName, meta ? meta.title || meta.key || meta.text || meta.usecase || meta.programName : null);
            }
        }
        // Tracking to appcues in both prod and dev
        this.appcuesTrack(eventName, meta);
        if (eventsHookMapper[eventName]) {
            eventsHookMapper[eventName](meta);
        }
    };
    Tracking.prototype.appcuesTrack = function (eventName, meta) {
        if (window.Appcues) {
            window.Appcues.track(eventName, meta);
        }
    };
    Tracking.prototype.mixPanelTrack = function (eventName, meta) {
        var env = globalConstants.ENV;
        var currentUser = getUserFromStore();
        if (env === 'prod' && currentUser && currentUser.email && currentUser.email.indexOf('@neo') > -1) {
            logger.log('not tracking in mixpanel. User has @neo in the email', 'tracking');
        }
        else {
            mixpanel.track(eventName, meta);
        }
    };
    Tracking.prototype.gaTrack = function (eventCategory, eventName, eventLabel) {
        if (window.ga) {
            window.ga('send', 'event', eventCategory, eventName, eventLabel);
        }
    };
    Tracking.prototype.pageView = function () {
        mixpanel.track('Page View');
    };
    Tracking.prototype.routeChangeTracking = function (location) {
        // Track page view if page is not homepage or if homepage has query params
        this.mixPanelTrack('SANDBOX_PAGE_VIEW', {
            pathname: location.pathname,
            search: location.search,
        });
    };
    return Tracking;
}());
export default Tracking;
